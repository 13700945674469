import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import * as yup from "yup";
import { Input, Ripple, initTWE } from "tw-elements";
import CloseIcon from "@mui/icons-material/Close";
import "../../styles/loginpage.css";
import { Form, Formik } from "formik";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PersonIcon from "@mui/icons-material/Person";
import { useStoresContext } from "../../context/storesfetch";
import { AddStores } from "../../../services/allcustomers/apicalls";
import CancelButton from "../../buttons/cancelButton";
import SubmitButton from "../../buttons/submitFormButton";

initTWE({ Input, Ripple });
const initialValues = {
  customer_raw_id: "",
  customer_name: "",
  customer_lat: "",
  customer_lng: "",
  route_id: "",
  customer_division: "",
  customer_channel: "",
  customer_subchannel: "",
};
const checkoutSchema = yup.object().shape({
  customer_raw_id: yup
    .string()
    .max(40, "Store Id  must be at most 40 characters")
    .required("Store Id is required"),
  customer_name: yup
    .string()
    .max(40, "Store Name must be at most 40 characters")
    .required("Store Name is required"),
  customer_lat: yup
    .number()
    .typeError("Latitude must be a number")
    .min(-90, "Latitude must be between -90 and 90")
    .max(90, "Latitude must be between -90 and 90"),
  customer_lng: yup
    .number()
    .typeError("Longitude must be a number")
    .min(-180, "Longitude must be between -180 and 180")
    .max(180, "Longitude must be between -180 and 180"),
  route_id: yup.string().required("Route Name is required "),
});

export default function CreateStoreForm({
  onClose,
  routes,
  uniqueChannels,
  uniqueSubchannels,
  uniqueDivisions,
}) {
  const { toggleStoresUpdateFlag } = useStoresContext();

  const [addingCustomerLoading, setAddingCustomerLoading] = useState(false);

  const handleFormSubmit = async (values, { resetForm }) => {
    setAddingCustomerLoading(true);

    try {
      const { message } = await AddStores(values);
      if (message === "Store added successfully") {
        toast.success(message);
        toggleStoresUpdateFlag();
        onClose();
      } else {
        toast.error(message);
      }
    } catch (e) {
      toast.error("Error adding user please try again");
    } finally {
      setAddingCustomerLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }} autoComplete="off">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px 3px 6px #A8A8A81F",
        }}
        autoComplete="off"
        className="p-4 mb-5"
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "-0.22px",
            color: " rgba(75, 75, 75, 1)",
          }}
        >
          Add a new Store
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {routes && (
        <Formik
          initialValues={initialValues}
          validationSchema={checkoutSchema}
          onSubmit={(values, formikHelpers) =>
            handleFormSubmit(values, formikHelpers)
          }
        >
          {({ getFieldProps, setFieldValue, touched, errors }) => (
            <Form className="px-4 ">
              <div className="flex mb-2">
                <div className="flex  w-50 flex-col mr-4">
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    htmlFor="Store Id"
                    className="mb-2 input-label"
                  >
                    Store Id *
                  </label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter Store Id"
                    type="text"
                    autoComplete="Store Id"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          sx={{
                            display: "none",
                          }}
                          position="start"
                        >
                          <PersonIcon style={{ color: "#030391eb" }} />
                        </InputAdornment>
                      ),
                    }}
                    required
                    {...getFieldProps("customer_raw_id")}
                    error={
                      touched.customer_raw_id && Boolean(errors.customer_raw_id)
                    }
                    helperText={
                      touched.customer_raw_id && errors.customer_raw_id
                    }
                    className="mt-1 mb-4 rounded-lg"
                  />
                </div>
                <div className="flex w-50 flex-col">
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    htmlFor="Store Name"
                    className="mb-2 input-label"
                  >
                    Store Name *
                  </label>
                  <TextField
                    id="outlined-basic1"
                    variant="outlined"
                    placeholder="Enter Store Name"
                    type="text"
                    autoComplete="Store Name"
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          sx={{
                            display: "none",
                          }}
                          position="start"
                        >
                          <PersonIcon style={{ color: "#030391eb" }} />
                        </InputAdornment>
                      ),
                    }}
                    {...getFieldProps("customer_name")}
                    error={
                      touched.customer_name && Boolean(errors.customer_name)
                    }
                    helperText={touched.customer_name && errors.customer_name}
                    className="mt-1 mb-4 rounded-lg"
                  />
                </div>
              </div>
              <div className="flex mb-2">
                <div className="flex w-50 flex-col mr-4 ">
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    htmlFor="routeName"
                    className="mb-2 input-label"
                  >
                    Route Name *
                  </label>
                  <Autocomplete
                    options={Object.keys(routes)}
                    getOptionLabel={(option) => option}
                    sx={{
                      background: "white !important",
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                        lineHeight: "19px",
                        letterSpacing: "0px",
                        color: "#545050 !important",
                      },
                    }}
                    className="rounded-lg mt-1 mb-4"
                    onChange={(event, newValue) => {
                      setFieldValue("route_id", routes[newValue]);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Route"
                        variant="outlined"
                        error={touched.route_id && Boolean(errors.route_id)}
                        helperText={touched.route_id && errors.route_id}
                      />
                    )}
                  />
                </div>
                <div className="flex w-50 flex-col">
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    htmlFor="customer_division"
                    className="mb-2 input-label"
                  >
                    Store Division
                  </label>
                  <Autocomplete
                    freeSolo
                    options={uniqueDivisions.sort((a, b) => a.localeCompare(b))}
                    getOptionLabel={(option) => option}
                    sx={{
                      background: "white !important",
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                        lineHeight: "19px",
                        letterSpacing: "0px",
                        color: "#545050 !important",
                      },
                    }}
                    className="rounded-lg mt-1 mb-4"
                    onChange={(event, newValue) => {
                      setFieldValue("customer_division", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Division"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex mb-2">
                <div className="flex w-50 flex-col mr-4 ">
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    htmlFor="customer_channel"
                    className="mb-2 input-label"
                  >
                    Store Channel
                  </label>
                  <Autocomplete
                    freeSolo
                    options={uniqueChannels.sort((a, b) => a.localeCompare(b))}
                    getOptionLabel={(option) => option}
                    sx={{
                      background: "white !important",
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                        lineHeight: "19px",
                        letterSpacing: "0px",
                        color: "#545050 !important",
                      },
                    }}
                    className="rounded-lg mt-1 mb-4"
                    onChange={(event, newValue) => {
                      setFieldValue("customer_channel", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Channel"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
                <div className="flex w-50 flex-col">
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    htmlFor="customer_subchannel"
                    className="mb-2 input-label"
                  >
                    Store Sub-Channel
                  </label>
                  <Autocomplete
                    freeSolo
                    options={uniqueSubchannels.sort((a, b) =>
                      a.localeCompare(b)
                    )}
                    getOptionLabel={(option) => option}
                    sx={{
                      background: "white !important",
                      "& .MuiInputBase-input": {
                        fontSize: "14px",
                        lineHeight: "19px",
                        letterSpacing: "0px",
                        color: "#545050 !important",
                      },
                    }}
                    className="rounded-lg mt-1 mb-4"
                    onChange={(event, newValue) => {
                      setFieldValue("customer_subchannel", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Sub-Channel"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="flex mb-2">
                <div className="flex  w-50 flex-col mr-4">
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    htmlFor="Customer Lat "
                    className="mb-2 input-label"
                  >
                    Store Latitude
                  </label>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="ex: 37.7749 "
                    type="text"
                    autoComplete="Store Latitude"
                    {...getFieldProps("customer_lat")}
                    error={touched.customer_lat && Boolean(errors.customer_lat)}
                    helperText={touched.customer_lat && errors.customer_lat}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          sx={{
                            display: "none",
                          }}
                          position="start"
                        >
                          <PersonIcon style={{ color: "#030391eb" }} />
                        </InputAdornment>
                      ),
                    }}
                    className="mt-1 mb-4 rounded-lg"
                  />
                </div>
                <div className="flex w-50 flex-col">
                  <label
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "16px",
                    }}
                    htmlFor="Store Longitude"
                    className="mb-2 input-label"
                  >
                    Store Longitude
                  </label>
                  <TextField
                    id="outlined-basic1"
                    variant="outlined"
                    placeholder="ex:-122.4194"
                    type="text"
                    autoComplete="Store Longitude"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          sx={{
                            display: "none",
                          }}
                          position="start"
                        >
                          <PersonIcon style={{ color: "#030391eb" }} />
                        </InputAdornment>
                      ),
                    }}
                    {...getFieldProps("customer_lng")}
                    error={touched.customer_lng && Boolean(errors.customer_lng)}
                    helperText={touched.customer_lng && errors.customer_lng}
                    className="mt-1 mb-4 rounded-lg"
                  />
                </div>
              </div>

              <div className="text-end my-3">
                {" "}
                <CancelButton onClose={onClose} />
                <SubmitButton
                  disabled={addingCustomerLoading}
                  loading={addingCustomerLoading}
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
}
