import axios from "axios";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { dev_url } from "../../apiurls";

const createUniqueIdentifier = (id, role, name, date) => {
  // Abbreviate store name to a maximum of 4 characters
  const abbreviatedStoreName = name.length > 4 ? `${name.slice(0, 4)}` : name;

  // Combine the fields with limited lengths
  const combined = `${id.slice(0, 2)}-${role.slice(0, 2)}-${date.slice(
    0,
    2
  )}-${abbreviatedStoreName}`;

  // Ensure the combined string is a maximum of 10 characters
  return combined.length > 10 ? combined.slice(0, 10) : combined;
};

export const fetchSurveysData = async (
  setIsLoading,
  setSurveys,
  setFilteredSurveys
) => {
  setIsLoading(true);

  try {
    const { user_country } = jwtDecode(Cookies.get("Price_App_Token"));

    const response = await axios.get(
      `${dev_url}/surveys?user_country=${user_country}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );

    // Map the response data to include rowNumber
    let surveysData = [];
    surveysData = response.data.data.map((survey, index) => ({
      id: survey.survey_id,
      ...survey,
      rowNumber: index + 1,
      uniqueId: createUniqueIdentifier(
        survey.survey_id,
        survey.user_position,
        survey.user_name,
        survey.visit_start_time_stamp
      ),
    }));

    setSurveys(surveysData);
    setFilteredSurveys(surveysData);
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  } finally {
    setIsLoading(false);
  }
};

export const surveyQuestionsData = async (survey_id, setDataLoading) => {
  setDataLoading(true);
  try {
    const response = await axios.get(
      `${dev_url}/surveyQuestions/${survey_id}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    if (response.data) {
      const questions = response.data.data.map((question) => ({
        survey_ques_type: question.survey_ques_type,
        survey_answer: question.survey_answer,
        survey_ques_text: question.survey_ques_text,
        survey_ques_subtitle: question.survey_ques_subtitle,
      }));
      return { questions };
    }
    return { message: "No data found" };
  } catch (e) {
    const message = !e.response
      ? "Network error. Please check your connection and try again."
      : e.response.status >= 500
      ? "A server error occurred. Please try again later."
      : e.response.data.message || "An error occurred";
    return { message };
  } finally {
    setDataLoading(false);
  }
};

export const downloadSurveyPdf = async (data, setLoading, survey) => {
  try {
    setLoading(true);
    const response = await axios.post(
      `${dev_url}/surveyPdf`,
      {
        questions: data,
        details: survey,
      },
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );

    // Create a URL for the PDF blob
    const userName = survey.user_name.replace(" ", "_") || "user";
    const customerName = survey.customer_name.replace(" ", "_") || "store";
    const visitStartTimeStamp =
      survey.visit_start_time_stamp || "2024-06-11T14:35:10";
    const visitDateTime = new Date(visitStartTimeStamp);
    const dayMonthYear = visitDateTime
      .toISOString()
      .split("T")[0]
      .replace(/-/g, "_");
    const fileName = `${userName}_${customerName}_${dayMonthYear}.pdf`;

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: "application/pdf" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();

    // Show success message
    toast.success("File downloaded successfully");
  } catch (e) {
    if (!e.response) {
      toast.error(
        "Network error . Please check your connection and try again."
      );

      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      toast.error("A server error occurred. Please try again later.");
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      toast.error(e.response.data.message || "An error occurred");
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  } finally {
    setLoading(false);
  }
};

export const downloadSurveyCsv = async (data, setLoading, survey) => {
  try {
    setLoading(true);
    const response = await axios.post(
      `${dev_url}/surveyCsv`,
      {
        questions: data,
        details: survey,
      },
      {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );

    // Create a URL for the CSV blob
    const userName = survey.user_name.replace(" ", "_") || "user";
    const customerName = survey.customer_name.replace(" ", "_") || "store";
    const visitStartTimeStamp =
      survey.visit_start_time_stamp || "2024-06-11T14:35:10";
    const visitDateTime = new Date(visitStartTimeStamp);
    const dayMonthYear = visitDateTime
      .toISOString()
      .split("T")[0]
      .replace(/-/g, "_");
    const fileName = `${userName}_${customerName}_${dayMonthYear}.csv`;

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();

    // Show success message
    toast.success("File downloaded successfully");
  } catch (e) {
    if (!e.response) {
      toast.error(
        "Network error . Please check your connection and try again."
      );

      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      toast.error("A server error occurred. Please try again later.");
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      toast.error(e.response.data.message || "An error occurred");
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  } finally {
    setLoading(false);
  }
};
