import axios from "axios";
import Cookies from "js-cookie";
import { dev_url } from "../../apiurls";
import { jwtDecode } from "jwt-decode";
const createUniqueIdentifier = (id, role, name, date) => {
  // Abbreviate store name to a maximum of 4 characters
  const abbreviatedStoreName = name.length > 4 ? `${name.slice(0, 4)}` : name;

  // Combine the fields with limited lengths
  const combined = `${id.slice(0, 2)}-${role.slice(0, 2)}-${date.slice(
    0,
    2
  )}-${abbreviatedStoreName}`;

  // Ensure the combined string is a maximum of 10 characters
  return combined.length > 10 ? combined.slice(0, 10) : combined;
};
// searchGalleryImages
export const fetchGalleryImagesData = async (
  setIsLoading,
  setVisits,
  data,
  page,
  pageSize,
  setTotalVisits
) => {
  setIsLoading(true);
  try {
    const response = await axios.post(
      `${dev_url}/searchGalleryImages`,

      { ...data, page, pageSize },

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );

    // Map the response data to include rowNumber
    let visitsData = [];
    visitsData = response.data.data.map((visit, index) => ({
      id: visit.visit_id,
      ...visit,
      rowNumber: index + 1,
      uniqueId: createUniqueIdentifier(
        visit.visit_id,
        visit.user_name,
        visit.customer_name,
        visit.visit_date
      ),
    }));
    setVisits(visitsData);
    setTotalVisits(response.data.total);
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  } finally {
    setIsLoading(false);
  }
};

export const fetchVisitsData = async (
  setIsLoading,
  setVisits,
  data
  // page,
  // pageSize,
  // setTotalVisits
) => {
  setIsLoading(true);
  try {
    const response = await axios.post(
      `${dev_url}/searchGallery`,

      data,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );

    // Map the response data to include rowNumber
    let visitsData = [];
    visitsData = response.data.data.map((visit, index) => ({
      id: visit.visit_id,
      ...visit,
      rowNumber: index + 1,
      uniqueId: createUniqueIdentifier(
        visit.visit_id,
        visit.user_name,
        visit.customer_name,
        visit.visit_date
      ),
    }));

    setVisits(visitsData);
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  } finally {
    setIsLoading(false);
  }
};

export const fetchAutocompleteResults = async (query, field) => {
  try {
    const response = await axios.post(
      `${dev_url}/searchResults`,
      {
        value: query,
        field: field,
        user_country: jwtDecode(Cookies.get("Price_App_Token")).user_country,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
        },
      }
    );
    return response.data.search_results;
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};

export const fetchVisitDetails = async (visitId) => {
  try {
    const response = await axios.get(`${dev_url}/visitDetails/${visitId}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("Price_App_Token")}`,
      },
    });
    return response.data;
  } catch (e) {
    if (!e.response) {
      // Network error or server is down
      return {
        message: "Network error . Please check your connection and try again.",
      };
    } else if (e.response.status >= 500) {
      // Server error
      return {
        message: "A server error occurred. Please try again later.",
      };
    } else {
      // Other errors
      return {
        message: e.response.data.message || "An error occurred",
      };
    }
  }
};
