import React from "react";
import { Menu, MenuItem, Typography } from "@mui/material";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import "../../styles/menus.css";

const SkuPricesMenu = React.memo(({ skuPrices, anchorEl, handleClose }) => {
  const open = Boolean(anchorEl);

  return (
    <Menu
      sx={{
        padding: "0.1rem 0.2rem !important",
        zIndex: "100000000000",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <MenuItem
        className="rounded mt-1"
        disabled
        sx={{ opacity: "1 !important" }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "12px",
            fontWeight: "600",
            lineHeight: "18px",
            color: "rgba(57, 71, 83, 1)",
          }}
        >
          Price Tags
        </Typography>
      </MenuItem>
      {Object.entries(skuPrices).map(([brand, price]) => (
        <MenuItem className="rounded mt-1" key={brand} onClick={handleClose}>
          <SellOutlinedIcon
            sx={{
              fontSize: "15px",
              marginRight: "5px",
              color: "rgba(57, 71, 83, 1)",
            }}
          />
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "18px",
              color: "rgba(87, 110, 128, 1)",
            }}
          >
            {brand}: {price}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );
});

export default SkuPricesMenu;
