export const countries_Center_geoLocations = {
  "Trinidad and Tobago": [10.6918, -61.2225],
  Suriname: [3.9193, -56.0278],
  Guyana: [4.8604, -58.9302],
  Barbados: [13.1939, -59.5432],
  Jamaica: [18.1096, -77.2975], // Corrected coordinates for Jamaica
};

export const cities = [
  {
    name: "Select a city",
    value: "",
  },
  { name: "Arima", value: "Arima" },
  { name: "Diego Martin", value: "Diego Martin" },
  {
    name: "Princess Town",
    value: "Princess Town",
  },
  {
    name: "Port of Spain",
    value: "Port of Spain",
  },
];

export const roles = [
  "Merchandiser",
  "Sales Supervisor",
  "Sales Representative",
  "Merchandiser Supervisor",
  "Administrator",
  "Manager",
];
export const nonAdminRoles = [
  "Merchandiser",
  "Sales Supervisor",
  "Sales Representative",
  "Merchandiser Supervisor",
  "Manager",
];

export const access_type = ["Dashboard", "Mobile Application", "Both of them"];

export const categories = ["Price", "Displayed"];

export const skus_sizes = [
  "200ml",
  "207ml",
  "237ml",
  "250ml",
  "275ml",
  "280ml",
  "296ml",
  "300ml",
  "315ml",
  "320ml",
  "330ml",
  "335ml",
  "355ml",
  "360ml",
  "370ml",
  "410ml",
  "473ml",
  "475ml",
  "500ml",
  "540ml",
  "590ml",
  "591ml",
  "650ml",
  "750ml",
  "1L",
  "1.5L",
  "2L",
  "4L",
  "5L",
];

export const status = ["Active", "Inactive"];
