import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import ImageViewer from "react-simple-image-viewer";
import dayjs from "dayjs";
import "../styles/galleryCard.css";
import { Person, Store, CalendarToday, LocationOn } from "@mui/icons-material";
import VrpanoOutlinedIcon from "@mui/icons-material/VrpanoOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MapModal from "../mapModal";
import SkuCountMenu from "../Menus/countsMenu";
import SkuPricesMenu from "../Menus/priceTagsMenu";

/// for lazy loading for better performance
const useIntersectionObserver = (callback) => {
  const observerRef = React.useRef(null);

  const observe = (element) => {
    if (!element) return;
    if (observerRef.current) observerRef.current.disconnect();

    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          callback();
          observerRef.current.disconnect(); // Stop observing after first load
        }
      },
      { threshold: 0.1 }
    );

    observerRef.current.observe(element);
  };

  return observe;
};

const CustomCard = React.memo(
  ({
    isPanorama,
    images,
    uniqueId,
    user_name,
    customer_name,
    visit_date,
    visit_location,
    sku_counts,
    category,
    sku_prices,
  }) => {
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    ////// image viewer configurations
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const openImageViewer = (index) => {
      setCurrentImageIndex(index);
      setIsViewerOpen(true);
    };

    const closeImageViewer = () => {
      setIsViewerOpen(false);
    };

    /////////////// count menu configuration
    const [skuMenuAnchorEl, setSkuMenuAnchorEl] = useState(null);

    const handleOpenSkuMenu = (event) => {
      setSkuMenuAnchorEl(event.currentTarget);
    };

    const handleCloseSkuMenu = useCallback(() => {
      setSkuMenuAnchorEl(null);
    }, []);
    //////////////////////////////////////////
    /////////////// price tags menu configuration
    const [skuPriceTagMenuAnchol, setPriceSkuMenuAnchorEl] = useState(null);

    const handleOpenPriceSkuMenu = (event) => {
      setPriceSkuMenuAnchorEl(event.currentTarget);
    };

    const handleClosePriceSkuMenu = useCallback(() => {
      setPriceSkuMenuAnchorEl(null);
    }, []);
    //////////////////////////////////////////
    // Handlers to go to previous or next image
    const handlePrevious = useCallback(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
    }, [images]);

    const handleNext = useCallback(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, [images]);
    ///// config to show the map modal
    const [showModal, setShowModal] = useState(false);
    const [mapCoords, setMapCoords] = useState([0, 0]);
    const handleShowMap = (coords) => {
      const parsedCoords = coords.split(",").map(Number);
      setMapCoords(parsedCoords);
      setShowModal(true);
    };

    const handleCloseModal = () => {
      setShowModal(false);
    };
    /////
    const [imageVisible, setImageVisible] = useState(false);
    const handleLazyLoad = useIntersectionObserver(() => setImageVisible(true));

    const handleImagesLoad = () => {
      setImagesLoaded(true);
    };

    useEffect(() => {
      let loadedImagesCount = 0;
      images.forEach((src) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          loadedImagesCount++;
          if (loadedImagesCount === images.length) {
            handleImagesLoad();
          }
        };
      });
    }, [images]);

    return (
      <>
        <Card
          ref={handleLazyLoad}
          sx={{
            height: "320px",
            maxHeight: "320px",
            borderRadius: "15px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            position: "relative",
            overflow: "hidden",
          }}
        >
          {/* Background Image for the whole card */}
          <div
            onClick={() => openImageViewer(currentImageIndex)}
            style={{
              backgroundImage: `url(${images[currentImageIndex]})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: "brightness(60%)", // Darken the image for text readability
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
          />

          {/* Left Arrow (only for panorama images) */}
          {isPanorama && images.length > 1 && (
            <IconButton
              onClick={handlePrevious}
              sx={{
                position: "absolute",
                top: "35%",
                left: 0,
                zIndex: 2000,
                transform: "translateY(-50%)",
              }}
            >
              <ArrowCircleLeftOutlinedIcon
                sx={{ fontSize: "30px", color: "white" }}
              />
            </IconButton>
          )}

          {/* Right Arrow (only for panorama images) */}
          {isPanorama && images.length > 1 && (
            <IconButton
              onClick={handleNext}
              sx={{
                position: "absolute",
                top: "35%",
                right: 0,
                zIndex: 2000,
                transform: "translateY(-50%)",
              }}
            >
              <ArrowCircleRightOutlinedIcon
                sx={{ fontSize: "30px", color: "white" }}
              />
            </IconButton>
          )}

          {isPanorama && (
            <IconButton
              sx={{
                position: "absolute",
                top: 10,
                left: 10,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                borderRadius: "8px",
                padding: "4px",
                cursor: "initial !important",
              }}
            >
              <VrpanoOutlinedIcon sx={{ fontSize: "24px", color: "#000" }} />
            </IconButton>
          )}
          {(category !== "Price Tag" && Object.keys(sku_counts).length > 0) ||
          (category === "Price Tag" && Object.keys(sku_prices).length > 0) ? (
            <IconButton
              onClick={
                category !== "Price Tag"
                  ? handleOpenSkuMenu
                  : handleOpenPriceSkuMenu
              }
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                backgroundColor: "transparent",
                color: "white",
                borderRadius: "8px",
                padding: "4px",
                cursor: "pointer !important",
                fontWeight: "600",
              }}
            >
              <MoreVertIcon sx={{ fontSize: "40px", color: "white" }} />
            </IconButton>
          ) : null}

          {/* Card Content */}
          <CardContent
            sx={{
              position: "absolute",

              bottom: "-5%",
              width: "100%",
              height: "70%", // Adjust this percentage to ensure the content section height is responsive
              color: "#fff",
              padding: "16px",
              background:
                "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)",
            }}
          >
            {/* Title (Unique ID) */}
            {/* <Typography
              onClick={() => openImageViewer(currentImageIndex)}
              variant="h6"
              component="div"
              sx={{
                fontFamily: "Poppins",
                fontSize: "22px",
                fontWeight: 700,
                lineHeight: "33px",
                letterSpacing: "-0.01em",
                textAlign: "left",
                color: "rgba(255, 255, 255, 1)",
                cursor: "pointer",
              }}
            >
              {uniqueId}
            </Typography> */}

            {/* User Information */}
            <Grid
              onClick={() => openImageViewer(currentImageIndex)}
              container
              alignItems="center"
              spacing={1}
              sx={{
                width: "100%", // Ensure the container takes full width of the card
                overflow: "hidden", // Hide overflow
                whiteSpace: "nowrap", // Do not wrap text onto the next line
                textOverflow: "ellipsis", // Add ellipsis for overflow
                marginTop: "1rem",
                cursor: "pointer",
                flexWrap: "nowrap",
              }}
            >
              <Grid item>
                <Person fontSize="small" />
              </Grid>
              <Grid item sx={{ flexGrow: 1, maxWidth: "calc(100% - 24px)" }}>
                <Tooltip title={user_name} placement="top" arrow>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "11px",
                      fontWeight: 400,
                      lineHeight: "16.5px",
                      letterSpacing: "-0.01em",
                      textAlign: "left",
                      color: "rgba(212, 220, 226, 1)",
                      paddingTop: "0.3rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {user_name}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>

            {/* Store Information */}
            <Grid
              onClick={() => openImageViewer(currentImageIndex)}
              container
              alignItems="center"
              spacing={1}
              sx={{
                width: "100%", // Ensure the container takes full width of the card
                overflow: "hidden", // Hide overflow
                whiteSpace: "nowrap", // Do not wrap text onto the next line
                textOverflow: "ellipsis", // Add ellipsis for overflow
                marginTop: "4px",
                cursor: "pointer",
                flexWrap: "nowrap",
              }}
            >
              <Grid item>
                <Store fontSize="small" />
              </Grid>
              <Grid item sx={{ flexGrow: 1, maxWidth: "calc(100% - 24px)" }}>
                <Tooltip title={customer_name} placement="top" arrow>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "11px",
                      fontWeight: 400,
                      lineHeight: "16.5px",
                      letterSpacing: "-0.01em",
                      textAlign: "left",
                      color: "rgba(212, 220, 226, 1)",
                      paddingTop: "0.3rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {customer_name}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>

            {/* Date Information */}
            <Grid
              onClick={() => openImageViewer(currentImageIndex)}
              container
              alignItems="center"
              spacing={1}
              sx={{ marginTop: "4px", cursor: "pointer" }}
            >
              <Grid item>
                <CalendarToday fontSize="small" />
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "11px",
                    fontWeight: 400,
                    lineHeight: "16.5px",
                    letterSpacing: "-0.01em",
                    textAlign: "left",
                    color: "rgba(212, 220, 226, 1)",
                    paddingTop: "0.3rem",
                  }}
                >
                  {dayjs(visit_date).format("DD/MM/YYYY")}
                </Typography>
              </Grid>
            </Grid>
            {/* Show on Map Button */}

            <Grid
              container
              alignItems="center"
              spacing={1}
              sx={{ marginTop: "4px" }}
            >
              <Grid item>
                <LocationOn fontSize="small" />
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  onClick={() => handleShowMap(visit_location)}
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "11px",
                    fontWeight: 400,
                    lineHeight: "16.5px",
                    letterSpacing: "-0.01em",
                    textAlign: "left",
                    color: "rgba(212, 220, 226, 1)",
                    cursor: "pointer",
                    paddingTop: "0.3rem",
                  }}
                >
                  Show on map
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <MapModal
            show={showModal}
            onHide={handleCloseModal}
            coords={mapCoords}
          />
        </Card>
        {isViewerOpen && (
          <ImageViewer
            closeOnClickOutside={true}
            src={images}
            currentIndex={currentImageIndex}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
          />
        )}
        {sku_counts && (
          <SkuCountMenu
            skuCounts={sku_counts}
            anchorEl={skuMenuAnchorEl}
            handleClose={handleCloseSkuMenu}
          />
        )}
        {sku_prices && (
          <SkuPricesMenu
            skuPrices={sku_prices}
            anchorEl={skuPriceTagMenuAnchol}
            handleClose={handleClosePriceSkuMenu}
          />
        )}
      </>
    );
  }
);

export default CustomCard;
