import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  InputAdornment,
  CircularProgress,
  Autocomplete,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import SellIcon from "@mui/icons-material/Sell";
import AddIcon from "@mui/icons-material/Add";
import StarIcon from "@mui/icons-material/Star";
import { Person, CalendarToday } from "@mui/icons-material";
import StoreIcon from "@mui/icons-material/Store";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import debounce from "lodash/debounce";
import { fetchAutocompleteResults } from "../../services/gallery/apicalls";
import "../styles/galleryfilters.css";
import { categories } from "../../data/data";

function SearchBar({ onSearch, initialData }) {
  const { user_country } = jwtDecode(Cookies.get("Price_App_Token"));

  const [values, setValues] = useState({
    ...initialData,
    user_country: user_country,
    startDate: null, // Handling start date
    endDate: null, // Handling end date
  });

  const [userOptions, setUserOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [productsOptions, setProductsOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [subChannelOptions, setSubChannelOptions] = useState([]);
  const [zonesOptions, setZonesOptions] = useState([]);
  const [loading, setLoading] = useState({
    user_name: false,
    customer_name: false,
    sku_brand: false,
    sku_full_name: false,
    sku_category: false,
    customer_channel: false,
    customer_division: false,
    customer_subchannel: false,
    upload_zone: false,
  });
  const [filterVisibility, setFilterVisibility] = useState({
    user_name: true,
    customer_name: true,
    sku_brand: true,
    sku_category: true,
    sku_full_name: false,
    customer_channel: false,
    customer_division: false,
    customer_subchannel: false,
    upload_zone: false,
    visit_date: false,
    upload_category: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleDateChange = (date, name) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setValues({
      ...values,
      [name]: formattedDate,
    });
  };

  ////// mapping the filter labels for the menu
  const filterLabels = {
    user_name: "User Name",
    customer_name: "Customer Name",
    visit_date: "Visit Date",
    sku_brand: "Brand",
    sku_full_name: "Product Name",
    sku_category: "Category",
    customer_channel: "Store Channel",
    customer_division: "Store Division",
    customer_subchannel: "Store Sub Channel",
    upload_zone: "Asset Type",
    upload_category: "Image Type",
  };

  /////////////// AUTO COMPLETE FUNCTIONS
  const fetchResults = debounce(async (inputValue, field) => {
    setLoading((prev) => ({ ...prev, [field]: true }));
    try {
      const data = await fetchAutocompleteResults(inputValue, field);
      if (field === "user_name") {
        setUserOptions(data);
      } else if (field === "customer_name") {
        setCustomerOptions(data);
      } else if (field === "sku_brand") {
        setBrandOptions(data);
      } else if (field === "sku_category") {
        setCategoryOptions(data);
      } else if (field === "customer_division") {
        setDivisionOptions(data);
      } else if (field === "customer_channel") {
        setChannelOptions(data);
      } else if (field === "customer_subchannel") {
        setSubChannelOptions(data);
      } else if (field === "upload_zone") {
        setZonesOptions(data);
      } else {
        setProductsOptions(data);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading((prev) => ({ ...prev, [field]: false }));
    }
  }, 500);

  const handleAutocompleteChange = (event, newValue, reason, field) => {
    if (reason === "input") {
      const inputValue = event.target.value;
      setValues((prevData) => ({
        ...prevData,
        [field]: inputValue,
      }));
      if (inputValue.length > 0 && field !== "upload_category") {
        fetchResults(inputValue, field);
      }
    }

    if (reason === "reset" && newValue) {
      setValues((prevData) => ({
        ...prevData,
        [field]: newValue,
      }));
    }
  };

  useEffect(() => {
    // Ensure the initial values are passed to the parent component
    onSearch(values);
  }, [user_country]);
  const handleSearch = () => {
    const searchValues = {
      ...values,
      visit_date: `${values.startDate},${values.endDate}`,
    };
    onSearch(searchValues);
  };

  const handleReset = () => {
    const resetValues = {
      user_name: "",
      customer_name: "",
      visit_date: null,
      startDate: null,
      endDate: null,
      user_country: user_country,
      sku_brand: "",
      sku_full_name: "",
      sku_category: "",
      customer_division: "",
      customer_channel: "",
      customer_subchannel: "",
      upload_zone: "",
      upload_category: "",
    };
    setValues(resetValues);
    onSearch(resetValues);
    setCustomerOptions([]);
    setBrandOptions([]);
    setUserOptions([]);
    setProductsOptions([]);
    setCategoryOptions([]);
    setChannelOptions([]);
    setDivisionOptions([]);
    setSubChannelOptions([]);
    setZonesOptions([]);
  };

  /////////// functions to be triggered when the add button is clicked
  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  //// hiding and showing the filters
  const handleFilterToggle = (filter) => {
    setFilterVisibility((prevVisibility) => {
      const isVisible = prevVisibility[filter];
      return {
        ...prevVisibility,
        [filter]: !isVisible,
      };
    });

    // Set value to empty string if filter is being removed
    if (filterVisibility[filter]) {
      setValues((prevValues) => ({
        ...prevValues,
        [filter]: "",
      }));
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: "1rem" }}
      >
        <Grid item>
          <h6
            style={{
              margin: 0,
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "600",
              lineHeight: "42px",
            }}
          >
            Search by
          </h6>
        </Grid>
        <Grid item xs={12} sm="auto">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "100%",
            }}
          >
            <button
              type="button"
              onClick={handleSearch}
              className="btn py-3 px-5 d-flex align-items-center justify-content-center"
              style={{
                background: "rgba(19, 67, 149, 1)",
                borderRadius: "30px",
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "25px",
                fontWeight: "600",
                color: "#FFFFFF",
                letterSpacing: "-0.16px",
                height: "40px",
                width: "66.67%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "140px",
              }}
            >
              Search
            </button>
            <button
              type="button"
              onClick={handleReset}
              className="btn py-3 px-5 d-flex align-items-center"
              style={{
                background: "transparent",
                border: "1px solid rgba(19, 67, 149, 1)",
                borderRadius: "50px",
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: "500",
                color: "rgba(19, 67, 149, 1)",
                letterSpacing: "-0.16px",
                height: "40px",
                width: "33.33%", // Takes 1/3 of the width
                textAlign: "center", // Center text alignment
                display: "flex", // Flex to center the content
                justifyContent: "center", // Horizontally center the text
                alignItems: "center", // Vertically center the text
                maxWidth: "90px",
              }}
            >
              Reset
            </button>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        sx={{
          width: "100%",
          marginBottom: "1rem",
          flexWrap: { xs: "wrap" },
          gap: "1rem",
        }}
      >
        {filterVisibility.user_name && (
          <Grid item xs={12} sm={2.4}>
            <Autocomplete
              id="user-autocomplete"
              freeSolo
              disableClearable
              options={userOptions}
              getOptionLabel={(option) => option || ""}
              value={values.user_name || ""}
              onInputChange={(event, newValue, reason) =>
                handleAutocompleteChange(event, newValue, reason, "user_name")
              }
              isOptionEqualToValue={(option, value) =>
                option.toLowerCase().includes(value.toLowerCase()) ||
                value === "" ||
                !option
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="user_name"
                  placeholder="
                   User"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {loading.user_name ? (
                          <CircularProgress color="inherit" size={15} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    style: {
                      height: "40px",
                      padding: "5px 16px",
                      maxWidth: "220px",
                      boxShadow: "0px 3px 6px #a8a8a833",
                      borderRadius: "50px",
                    },
                  }}
                  className="custom-autocomplete-input"
                />
              )}
            />
          </Grid>
        )}
        {filterVisibility.customer_name && (
          <Grid item xs={12} sm={2.4}>
            <Autocomplete
              freeSolo
              disableClearable
              id="customer-autocomplete"
              options={customerOptions}
              getOptionLabel={(option) => option || ""}
              value={values.customer_name || ""}
              onInputChange={(event, newValue, reason) =>
                handleAutocompleteChange(
                  event,
                  newValue,
                  reason,
                  "customer_name"
                )
              }
              isOptionEqualToValue={(option, value) =>
                option.toLowerCase().includes(value.toLowerCase()) ||
                value === "" ||
                !option
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="customer_name"
                  placeholder="
                   Store"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <StoreIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {loading.customer_name ? (
                          <CircularProgress color="inherit" size={15} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    style: {
                      height: "40px",
                      padding: "5px 16px",
                      maxWidth: "220px",
                      boxShadow: "0px 3px 6px #a8a8a833",
                      borderRadius: "50px",
                    },
                  }}
                  className="custom-autocomplete-input"
                />
              )}
            />
          </Grid>
        )}

        {filterVisibility.upload_zone && (
          <Grid item xs={12} sm={2.4}>
            <Autocomplete
              id="zone-autocomplete"
              freeSolo
              disableClearable
              options={zonesOptions}
              getOptionLabel={(option) => option || ""}
              value={values.upload_zone || ""}
              onInputChange={(event, newValue, reason) =>
                handleAutocompleteChange(event, newValue, reason, "upload_zone")
              }
              isOptionEqualToValue={(option, value) =>
                option.toLowerCase().includes(value.toLowerCase()) ||
                value === "" ||
                !option
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="upload_zone"
                  placeholder="
                   Asset Type"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SellIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {loading.upload_zone ? (
                          <CircularProgress color="inherit" size={15} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    style: {
                      height: "40px",
                      padding: "5px 16px",
                      maxWidth: "220px",
                      boxShadow: "0px 3px 6px #a8a8a833",
                      borderRadius: "50px",
                    },
                  }}
                  className="custom-autocomplete-input"
                />
              )}
            />
          </Grid>
        )}
        {filterVisibility.customer_division && (
          <Grid item xs={12} sm={2.4}>
            <Autocomplete
              freeSolo
              disableClearable
              id="division-autocomplete"
              options={divisionOptions}
              getOptionLabel={(option) => option || ""}
              value={values.customer_division || ""}
              onInputChange={(event, newValue, reason) =>
                handleAutocompleteChange(
                  event,
                  newValue,
                  reason,
                  "customer_division"
                )
              }
              isOptionEqualToValue={(option, value) =>
                option.toLowerCase().includes(value.toLowerCase()) ||
                value === "" ||
                !option
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="customer_division"
                  placeholder="
                   Division"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <StoreIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {loading.customer_division ? (
                          <CircularProgress color="inherit" size={15} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    style: {
                      height: "40px",
                      padding: "5px 16px",
                      maxWidth: "220px",
                      boxShadow: "0px 3px 6px #a8a8a833",
                      borderRadius: "50px",
                    },
                  }}
                  className="custom-autocomplete-input"
                />
              )}
            />
          </Grid>
        )}
        {filterVisibility.customer_channel && (
          <Grid item xs={12} sm={2.4}>
            <Autocomplete
              freeSolo
              disableClearable
              id="channel-autocomplete"
              options={channelOptions}
              getOptionLabel={(option) => option || ""}
              value={values.customer_channel || ""}
              onInputChange={(event, newValue, reason) =>
                handleAutocompleteChange(
                  event,
                  newValue,
                  reason,
                  "customer_channel"
                )
              }
              isOptionEqualToValue={(option, value) =>
                option.toLowerCase().includes(value.toLowerCase()) ||
                value === "" ||
                !option
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="customer_name"
                  placeholder="
                   Store Channel"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <StoreIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {loading.customer_channel ? (
                          <CircularProgress color="inherit" size={15} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    style: {
                      height: "40px",
                      padding: "5px 16px",
                      maxWidth: "220px",
                      boxShadow: "0px 3px 6px #a8a8a833",
                      borderRadius: "50px",
                    },
                  }}
                  className="custom-autocomplete-input"
                />
              )}
            />
          </Grid>
        )}

        {filterVisibility.customer_subchannel && (
          <Grid item xs={12} sm={2.4}>
            <Autocomplete
              freeSolo
              disableClearable
              id="subchannel-autocomplete"
              options={subChannelOptions}
              getOptionLabel={(option) => option || ""}
              value={values.customer_subchannel || ""}
              onInputChange={(event, newValue, reason) =>
                handleAutocompleteChange(
                  event,
                  newValue,
                  reason,
                  "customer_subchannel"
                )
              }
              isOptionEqualToValue={(option, value) =>
                option.toLowerCase().includes(value.toLowerCase()) ||
                value === "" ||
                !option
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="customer_subchannel"
                  placeholder=" Store Sub Channel"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <StoreIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {loading.customer_subchannel ? (
                          <CircularProgress color="inherit" size={15} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    style: {
                      height: "40px",
                      padding: "5px 16px",
                      maxWidth: "220px",
                      boxShadow: "0px 3px 6px #a8a8a833",
                      borderRadius: "50px",
                    },
                  }}
                  className="custom-autocomplete-input"
                />
              )}
            />
          </Grid>
        )}
        {filterVisibility.sku_category && (
          <Grid item xs={12} sm={2.4}>
            <Autocomplete
              disableClearable
              freeSolo
              id="brand-autocomplete"
              options={categoryOptions}
              getOptionLabel={(option) => option || ""}
              value={values.sku_category || ""}
              onInputChange={(event, newValue, reason) =>
                handleAutocompleteChange(
                  event,
                  newValue,
                  reason,
                  "sku_category"
                )
              }
              isOptionEqualToValue={(option, value) =>
                option.toLowerCase().includes(value.toLowerCase()) ||
                value === "" ||
                !option
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="sku_category"
                  placeholder=" Category"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <StarIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {loading.sku_category ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    style: {
                      height: "40px",
                      padding: "5px 16px",
                      maxWidth: "220px",
                      boxShadow: "0px 3px 6px #a8a8a833",
                      borderRadius: "50px",
                    },
                  }}
                  className="custom-autocomplete-input"
                />
              )}
            />
          </Grid>
        )}
        {filterVisibility.sku_brand && (
          <Grid item xs={12} sm={2.4}>
            <Autocomplete
              disableClearable
              freeSolo
              id="brand-autocomplete"
              options={brandOptions}
              getOptionLabel={(option) => option || ""}
              value={values.sku_brand || ""}
              onInputChange={(event, newValue, reason) =>
                handleAutocompleteChange(event, newValue, reason, "sku_brand")
              }
              isOptionEqualToValue={(option, value) =>
                option.toLowerCase().includes(value.toLowerCase()) ||
                value === "" ||
                !option
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="sku_brand"
                  placeholder=" Brand"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <StarIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {loading.sku_brand ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    style: {
                      height: "40px",
                      padding: "5px 16px",
                      maxWidth: "220px",
                      boxShadow: "0px 3px 6px #a8a8a833",
                      borderRadius: "50px",
                    },
                  }}
                  className="custom-autocomplete-input"
                />
              )}
            />
          </Grid>
        )}
        {filterVisibility.sku_full_name && (
          <Grid item xs={12} sm={2.4}>
            <Autocomplete
              disableClearable
              freeSolo
              id="fullName-autocomplete"
              options={productsOptions}
              getOptionLabel={(option) => option || ""}
              value={values.sku_full_name || ""}
              onInputChange={(event, newValue, reason) =>
                handleAutocompleteChange(
                  event,
                  newValue,
                  reason,
                  "sku_full_name"
                )
              }
              isOptionEqualToValue={(option, value) =>
                option.toLowerCase().includes(value.toLowerCase()) ||
                value === "" ||
                !option
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="sku_full_name"
                  placeholder=" Product"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SellIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {loading.sku_full_name ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    style: {
                      height: "40px",
                      padding: "5px 16px",
                      maxWidth: "220px",
                      boxShadow: "0px 3px 6px #a8a8a833",
                      borderRadius: "50px",
                    },
                  }}
                  className="custom-autocomplete-input"
                />
              )}
            />
          </Grid>
        )}
        {filterVisibility.upload_category && (
          <Grid item xs={12} sm={2.4}>
            <Autocomplete
              disableClearable
              freeSolo
              id="fullName-autocomplete"
              options={categories}
              getOptionLabel={(option) => option || ""}
              value={values.upload_category || ""}
              onInputChange={(event, newValue, reason) =>
                handleAutocompleteChange(
                  event,
                  newValue,
                  reason,
                  "upload_category"
                )
              }
              isOptionEqualToValue={(option, value) =>
                option.toLowerCase().includes(value.toLowerCase()) ||
                value === "" ||
                !option
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  name="upload_category"
                  placeholder="Image Type"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SellIcon />
                      </InputAdornment>
                    ),

                    style: {
                      height: "40px",
                      padding: "5px 16px",
                      maxWidth: "220px",
                      boxShadow: "0px 3px 6px #a8a8a833",
                      borderRadius: "50px",
                    },
                  }}
                  className="custom-autocomplete-input"
                />
              )}
            />
          </Grid>
        )}
        {filterVisibility.visit_date && (
          <Grid item xs={12} sm={4.8}>
            {" "}
            {/* Adjusted the grid size to accommodate two inputs */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <DatePicker
                value={values.startDate ? dayjs(values.startDate) : null}
                onChange={(newDate) => handleDateChange(newDate, "startDate")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ width: "48%" }} // Making sure each date picker takes half of the container width
                    name="startDate"
                    placeholder="Start Date"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarToday />
                        </InputAdornment>
                      ),
                      style: {
                        display: "flex",
                        alignItems: "center",
                        padding: "0.5rem",
                      },
                    }}
                    className="custom-autocomplete-input"
                  />
                )}
              />
              <Box mx={0.5} fontSize="1.25rem">
                {" "}
                {/* Adjust margin and font size as needed */}-
              </Box>
              <DatePicker
                value={values.endDate ? dayjs(values.endDate) : null}
                onChange={(newDate) => handleDateChange(newDate, "endDate")}
                minDate={values.startDate ? dayjs(values.startDate) : null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ width: "48%" }} // Matching width with the start date picker
                    name="endDate"
                    placeholder="End Date"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarToday />
                        </InputAdornment>
                      ),
                      style: {
                        display: "flex",
                        alignItems: "center",
                        padding: "0.5rem",
                      },
                    }}
                    className="custom-autocomplete-input"
                  />
                )}
              />
            </div>
          </Grid>
        )}

        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "flex-end" },
            marginLeft: { sm: "auto" }, // Align the button to the right on larger screens
          }}
          xs={12}
          sm="auto"
        >
          <button
            style={{
              background: "transparent",
              border: "1px solid rgba(19, 67, 149, 1)",
              borderRadius: "50px",
              fontFamily: "Poppins",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "500",
              color: "rgba(19, 67, 149, 1)",
              letterSpacing: "-0.16px",
              height: "40px",
              padding: "6px 16px",
              opacity: "1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleAddClick}
          >
            <AddIcon sx={{ marginRight: "2px", fontSize: "22px" }} />
            <span style={{ marginTop: "0.1rem" }}> Add</span>
          </button>
        </Grid>
        <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
          {[
            "user_name",
            "customer_name",
            "sku_category",
            "sku_brand",
            "sku_full_name",
            "customer_division",
            "customer_channel",
            "customer_subchannel",
            "upload_zone",
            "visit_date",
            "upload_category",
          ].map((filter) => (
            <MenuItem
              sx={{
                padding: "4px 12px",
                marginBottom: "4px",
              }}
              key={filter}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterVisibility[filter]}
                    onChange={() => handleFilterToggle(filter)}
                    sx={{
                      color: "rgba(23, 71, 153, 1)", // Set the default unchecked color
                      "&.Mui-checked": {
                        color: "rgba(23, 71, 153, 1)", // Set the checked color to blue
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: "24px", // Adjust the size of the checkbox icon (similar to image)
                      },
                    }}
                  />
                }
                label={filterLabels[filter]}
              />
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    </LocalizationProvider>
  );
}

export default SearchBar;
