import { Box, Typography, Tabs, Tab } from "@mui/material";
import { Input, Ripple, initTWE } from "tw-elements";
import CloseIcon from "@mui/icons-material/Close";
import "../../styles/loginpage.css";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import EditBrandForm from "../../forms/editBrand";
import EditProduct from "../../forms/editProduct";

initTWE({ Input, Ripple });

export default function EditProductForm({
  user,
  onClose,
  brands,
  distributors,
  flavors,
  categories,
}) {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }} autoComplete="off">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px 3px 6px #A8A8A81F",
        }}
        autoComplete="off"
        className="p-4 "
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "-0.22px",
            color: " rgba(75, 75, 75, 1)",
          }}
        >
          Edit
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Tabs
        sx={{
          "& .MuiTab-root": {
            outline: "none",
          },
        }}
        className="mb-5"
        value={activeTab}
        onChange={handleTabChange}
      >
        <Tab sx={{ outline: "none" }} label="Edit Product" />
        <Tab label="Edit Brand" />
      </Tabs>

      {activeTab === 0 ? (
        <EditProduct
          user={user}
          onClose={onClose}
          brands={brands}
          distributors={distributors}
          flavors={flavors}
          categories={categories}
        />
      ) : (
        <EditBrandForm user={user} onClose={onClose} />
      )}
    </Box>
  );
}
