import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import ImageViewer from "react-simple-image-viewer";

import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { fetchVisitDetails } from "../../../services/gallery/apicalls";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";

export default function VisitProfileDrawer({ visitId, onClose, visit }) {
  const [dataLoading, setDataLoading] = useState(true);
  const [visitDetails, setVisitDetails] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [allImages, setAllImages] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [paginationState, setPaginationState] = useState({});

  const itemsPerPage = 24;

  useEffect(() => {
    const getVisitDetails = async () => {
      setDataLoading(true);
      try {
        const data = await fetchVisitDetails(visitId);
        setVisitDetails(data.data);
        // Initialize pagination state for each detail
        const initialPaginationState = data.data.reduce(
          (acc, detail, index) => {
            acc[index] = {
              currentPage: 1,
              hasMore: detail.image_urls.length > itemsPerPage,
              imagesLoaded: detail.image_urls.slice(0, itemsPerPage),
              totalImages: detail.image_urls.length,
            };
            return acc;
          },
          {}
        );
        setPaginationState(initialPaginationState);
        const images = data.data.flatMap((detail) => detail.image_urls);
        setAllImages(images);
      } catch (error) {
        toast.error("Error fetching visit details:", error);
      } finally {
        setDataLoading(false);
      }
    };

    getVisitDetails();
  }, [visitId]);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setCurrentImage(allImages[index]);
  };

  const closeImageViewer = () => {
    setCurrentImage(null);
    setCurrentImageIndex(0);
  };
  // const handleImagesLoad = () => {
  //   setImagesLoaded(true);
  // };
  // useEffect(() => {
  //   if (visitDetails.length > 0) {
  //     let images = visitDetails.flatMap((detail) => detail.image_urls);
  //     let loadedImagesCount = 0;

  //     images.forEach((src) => {
  //       const img = new Image();
  //       img.src = src;
  //       img.onload = () => {
  //         loadedImagesCount++;
  //         if (loadedImagesCount === images.length) {
  //           handleImagesLoad();
  //         }
  //       };
  //     });
  //   }
  // }, [visitDetails]);
  const loadMoreImages = (detailIndex) => {
    const currentPage = paginationState[detailIndex]?.currentPage || 1;
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    const allDetailImages = visitDetails[detailIndex].image_urls;
    const hasMoreImages = end < allDetailImages.length;

    setPaginationState((prevState) => ({
      ...prevState,
      [detailIndex]: {
        ...prevState[detailIndex],
        currentPage: currentPage + 1,
        hasMore: hasMoreImages, // Update hasMore correctly
        imagesLoaded: allDetailImages.slice(0, end), // Load up to the next set of images
      },
    }));
  };

  if (dataLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <Box sx={{ width: "100%" }} autoComplete="off">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px 3px 6px #A8A8A81F",
        }}
        autoComplete="off"
        className="p-4 mb-3"
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "30px",
            letterSpacing: "-0.22px",
            color: " #000000",
          }}
        >
          Gallery Details
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          padding: 4,
          backgroundColor: "#fff",
          margin: 2,
        }}
      >
        <Grid container spacing={2}>
          {/* Left column */}
          <Grid
            item
            xs={6}
            sx={{ borderRight: 1, borderColor: "divider", paddingRight: 2 }}
          >
            <Grid container>
              {[{ label: "Store Name", value: visit.customer_name }].map(
                (item, index) => (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: 1,
                      borderColor: "divider",
                      py: 1,
                      minHeight: "50px",
                    }}
                  >
                    <Typography
                      style={{
                        font: "normal normal 500 18px/22px Poppins",
                        letterSpacing: " -0.14px",
                        color: "#000000",
                        textTransform: " capitalize",
                        maxWidth: "200px", // Adjust the width as needed
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      title={item.label}
                    >
                      {item.label}
                    </Typography>
                    <Typography
                      style={{
                        font: "normal normal 400 16px/20px Poppins",
                        letterSpacing: " -0.14px",
                        color: "#03A9F4",
                        textTransform: " capitalize",
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      title={item.value}
                    >
                      {item.value}
                    </Typography>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>

          {/* Right column */}
          <Grid item xs={6}>
            <Grid container>
              {[{ label: "Done By", value: visit.user_name || "N/A" }].map(
                (item, index) => (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderBottom: 1,
                      borderColor: "divider",
                      py: 1,
                      minHeight: "50px",
                    }}
                  >
                    <Typography
                      style={{
                        font: "normal normal 500 18px/22px Poppins",
                        letterSpacing: " -0.14px",
                        color: "#000000",
                        textTransform: " capitalize",
                      }}
                    >
                      {item.label}
                    </Typography>
                    <Typography
                      style={{
                        font: "normal normal 400 16px/20px Poppins",
                        letterSpacing: " -0.14px",
                        color: "#03A9F4",
                        textTransform: " capitalize",
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
        </Grid>

        {visitDetails.map((detail, index) => (
          <Box sx={{ overFlow: "auto" }} key={index}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "500", fontFamily: "Poppins", marginTop: 2 }}
            >
              {detail.upload_category} - {detail.upload_zone}
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Box
              id={`scrollable-${index}`}
              style={{ maxHeight: "350px", height: "300px", overflow: "auto" }}
            >
              <InfiniteScroll
                dataLength={paginationState[index]?.imagesLoaded.length || 0}
                next={() => loadMoreImages(index)} // Load more when scrolling
                hasMore={paginationState[index]?.hasMore}
                scrollableTarget={`scrollable-${index}`}
              >
                <Grid container spacing={2}>
                  {paginationState[index]?.imagesLoaded.map((url, i) => (
                    <Grid item xs={6} sm={4} md={3} lg={2} key={i}>
                      <img
                        src={url}
                        alt={` ${i}`}
                        style={{
                          width: "100%",
                          height: "auto",
                          cursor: "pointer",
                          borderRadius: "8px",
                        }}
                        loading="lazy"
                        onClick={() => handleImageClick(allImages.indexOf(url))}
                      />
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            </Box>
          </Box>
        ))}

        {currentImage !== null && (
          <ImageViewer
            src={allImages}
            currentIndex={currentImageIndex}
            onClose={closeImageViewer}
            closeOnClickOutside={true}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain", // Ensures the image scales to fit within the viewer
            }}
          />
        )}
      </Box>
    </Box>
  );
}
