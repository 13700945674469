import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import * as yup from "yup";
import { Input, Ripple, initTWE } from "tw-elements";
import { Form, Formik } from "formik";
import PersonIcon from "@mui/icons-material/Person";
import { useState } from "react";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { useStoresContext } from "../../context/storesfetch";
import { editStore } from "../../../services/allcustomers/apicalls";
import CancelButton from "../../buttons/cancelButton";
import SubmitButton from "../../buttons/submitFormButton";

initTWE({ Input, Ripple });

const checkoutSchema = yup.object().shape({
  customer_name: yup
    .string()
    .max(40, "Store Name must be at most 40 characters")
    .required("Store Name is required"),
  customer_raw_id: yup
    .string()
    .max(40, "Store Id must be at most 40 characters")
    .required("Store ID  is required"),
  customer_lat: yup
    .number()
    .typeError("Latitude must be a number")
    .min(-90, "Latitude must be between -90 and 90")
    .max(90, "Latitude must be between -90 and 90"),
  customer_lng: yup
    .number()
    .typeError("Longitude must be a number")
    .min(-180, "Longitude must be between -180 and 180")
    .max(180, "Longitude must be between -180 and 180"),
  route_id: yup.string().required("Choose a route for this store"),
});

export default function EditCustomerForm({
  user,
  onClose,
  routes,
  uniqueChannels,
  uniqueSubchannels,
  uniqueDivisions,
}) {
  const initialValues = {
    customer_name: user.customer_name || "",
    customer_raw_id: user.customer_raw_id || "",
    route_id: routes[user.route_name],
    customer_lat: user.customer_lat ?? "",
    customer_lng: user.customer_lng ?? "",
    customer_channel: user.customer_channel || "",
    customer_division: user.customer_division || "",
    customer_subchannel: user.customer_subchannel || "",
  };
  const getRouteNameByRouteId = (routeId) => {
    for (const routeName in routes) {
      if (routes[routeName] === routeId) {
        return routeName;
      }
    }
    return ""; // Return an empty string or a default value if the route_id is not found
  };

  const { toggleStoresUpdateFlag } = useStoresContext();

  const [editRouteLoading, setEditRouteLoading] = useState(false);

  const handleFormSubmit = async (values, { resetForm }) => {
    setEditRouteLoading(true);
    try {
      const { message } = await editStore(values, user.customer_id);
      if (message === "Customer updated successfully") {
        toast.success("Customer updated successfully");
        toggleStoresUpdateFlag();
        onClose();
      } else {
        toast.error(message);
      }
    } catch (err) {
      const errorMessage =
        err.response && err.response.data.message
          ? err.response.data.message
          : "Error updating customer ,please try again";
      toast.error(errorMessage);
    } finally {
      setEditRouteLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%" }} autoComplete="off">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px 3px 6px #A8A8A81F",
        }}
        autoComplete="off"
        className="p-4 mb-5"
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            letterSpacing: "-0.22px",
            color: " rgba(75, 75, 75, 1)",
          }}
        >
          Edit Store
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        onSubmit={(values, formikHelpers) =>
          handleFormSubmit(values, formikHelpers)
        }
      >
        {({ getFieldProps, setFieldValue, touched, errors, values }) => (
          <Form className="px-4 ">
            <div className="flex mb-2">
              <div className="flex  w-50 flex-col mr-4">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="Route ID"
                  className="mb-2 input-label"
                >
                  Store ID*
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter Store ID "
                  type="text"
                  autoComplete="Store ID"
                  required
                  {...getFieldProps("customer_raw_id")}
                  error={
                    touched.customer_raw_id && Boolean(errors.customer_raw_id)
                  }
                  helperText={touched.customer_raw_id && errors.customer_raw_id}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon
                          sx={{
                            display: "none",
                          }}
                          style={{ color: "#030391eb" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1 mb-4 rounded-lg"
                />
              </div>
              <div className="flex w-50 flex-col">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="routeName"
                  className="mb-2 input-label"
                >
                  Store Name *
                </label>
                <TextField
                  id="outlined-basic1"
                  variant="outlined"
                  placeholder="Enter Store Name"
                  type="text"
                  autoComplete="Store Name"
                  required
                  {...getFieldProps("customer_name")}
                  error={touched.customer_name && Boolean(errors.customer_name)}
                  helperText={touched.customer_name && errors.customer_name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon
                          sx={{
                            display: "none",
                          }}
                          style={{ color: "#030391eb" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1 mb-4 rounded-lg"
                />
              </div>
            </div>
            <div className="flex mb-2">
              <div className="flex w-full flex-col mr-4">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="routeName"
                  className="mb-2 input-label"
                >
                  Route Name *
                </label>
                <Autocomplete
                  options={Object.keys(routes)}
                  getOptionLabel={(option) => option}
                  sx={{
                    background: "white !important",

                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                      lineHeight: "19px",
                      letterSpacing: "0px",
                      color: "#545050 !important",
                    },
                  }}
                  className="rounded-lg mt-1 mb-4"
                  value={getRouteNameByRouteId(values.route_id)}
                  onChange={(event, newValue) => {
                    setFieldValue("route_id", routes[newValue]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Route"
                      variant="outlined"
                      error={touched.route_id && Boolean(errors.route_id)}
                      helperText={touched.route_id && errors.route_id}
                    />
                  )}
                />
              </div>
              <div className="flex w-full flex-col">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="customer_division"
                  className="mb-2 input-label"
                >
                  Customer Division
                </label>
                <Autocomplete
                  freeSolo
                  options={uniqueDivisions.sort((a, b) => a.localeCompare(b))}
                  getOptionLabel={(option) => option}
                  sx={{
                    background: "white !important",

                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                      lineHeight: "19px",
                      letterSpacing: "0px",
                      color: "#545050 !important",
                    },
                  }}
                  className="rounded-lg mt-1 mb-4"
                  value={values.customer_division || ""}
                  onChange={(event, newValue) => {
                    setFieldValue("customer_division", newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setFieldValue("customer_division", newInputValue); // Same as add page
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...getFieldProps("customer_division")}
                      placeholder="Select Division"
                      variant="outlined"
                      autoComplete="off"
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex mb-2">
              <div className="flex w-full flex-col mr-4">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="customer_channel"
                  className="mb-2 input-label"
                >
                  Customer Channel
                </label>
                <Autocomplete
                  freeSolo
                  options={uniqueChannels.sort((a, b) => a.localeCompare(b))}
                  getOptionLabel={(option) => option}
                  sx={{
                    background: "white !important",

                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                      lineHeight: "19px",
                      letterSpacing: "0px",
                      color: "#545050 !important",
                    },
                  }}
                  className="rounded-lg mt-1 mb-4"
                  value={values.customer_channel || ""}
                  onChange={(event, newValue) => {
                    setFieldValue("customer_channel", newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setFieldValue("customer_channel", newInputValue); // Same as add page
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...getFieldProps("customer_channel")}
                      placeholder="Select Channel"
                      variant="outlined"
                      autoComplete="off"
                    />
                  )}
                />
              </div>
              <div className="flex w-full flex-col">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="customer_subchannel"
                  className="mb-2 input-label"
                >
                  Customer Sub-Channel
                </label>
                <Autocomplete
                  freeSolo
                  options={uniqueSubchannels.sort((a, b) => a.localeCompare(b))}
                  getOptionLabel={(option) => option}
                  sx={{
                    background: "white !important",

                    "& .MuiInputBase-input": {
                      fontSize: "14px",
                      lineHeight: "19px",
                      letterSpacing: "0px",
                      color: "#545050 !important",
                    },
                  }}
                  className="rounded-lg mt-1 mb-4"
                  value={values.customer_subchannel || ""}
                  onChange={(event, newValue) => {
                    setFieldValue("customer_subchannel", newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setFieldValue("customer_subchannel", newInputValue); // Same as add page
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...getFieldProps("customer_subchannel")}
                      placeholder="Select Sub-Channel"
                      variant="outlined"
                      autoComplete="off"
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex mb-2">
              <div className="flex  w-50 flex-col mr-4">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="Store Lat"
                  className="mb-2 input-label"
                >
                  Store Latitude
                </label>
                <TextField
                  id="outlined-basic2"
                  variant="outlined"
                  placeholder="ex: 37.7749 "
                  type="text"
                  autoComplete="Store Latitude"
                  {...getFieldProps("customer_lat")}
                  error={touched.customer_lat && Boolean(errors.customer_lat)}
                  helperText={touched.customer_lat && errors.customer_lat}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          display: "none",
                        }}
                        position="start"
                      >
                        <PersonIcon style={{ color: "#030391eb" }} />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1 mb-4 rounded-lg"
                />
              </div>
              <div className="flex w-50 flex-col">
                <label
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                  htmlFor="routeName"
                  className="mb-2 input-label"
                >
                  Store Longitude
                </label>
                <TextField
                  id="outlined-basic3"
                  variant="outlined"
                  placeholder="ex:-122.4194"
                  type="text"
                  autoComplete="Store Longitude"
                  {...getFieldProps("customer_lng")}
                  error={touched.customer_lng && Boolean(errors.customer_lng)}
                  helperText={touched.customer_lng && errors.customer_lng}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        sx={{
                          display: "none",
                        }}
                        position="start"
                      >
                        <PersonIcon style={{ color: "#030391eb" }} />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1 mb-4 rounded-lg"
                />
              </div>
            </div>

            <div className="text-end ">
              {" "}
              <CancelButton onClose={onClose} />
              <SubmitButton
                disabled={editRouteLoading}
                loading={editRouteLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

/////////// function to get route stores
//   const [customerwithRouteId, setCustomerswithRouteId] = useState([]);
//   const [customerwithoutRouteId, setCustomerswithoutRouteId] = useState([]);

//   useEffect(() => {
//     RouteStores(
//       user.route_id,
//       setCustomerswithRouteId,
//       setCustomerswithoutRouteId,
//       setLoading
//     );
//   }, []);
/* {customerwithoutRouteId.length > 0 && (
                <div className="flex mb-2 flex-col">
                  <label
                    htmlFor="stores_without_route"
                    className="mb-2 input-label"
                  >
                    Add Stores
                  </label>
                  <Autocomplete
                    multiple
                    id="customers-without-route-ids"
                    options={customerwithoutRouteId}
                    getOptionLabel={(option) => option.customer_name}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      setFieldValue(
                        "addedCustomers",
                        newValue.map((item) => item.customer_id)
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </div>
              )}
  
              <div className="flex mb-2 flex-col">
                <label className="mb-2 input-label">Remove Stores</label>
  
                <Autocomplete
                  multiple
                  id="customers-with-route-ids"
                  options={customerwithRouteId}
                  getOptionLabel={(option) => option.customer_name}
                  onChange={(event, newValue) => {
                    setFieldValue(
                      "removedCustomers",
                      newValue.map((item) => item.customer_id)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </div> */
////////////////////////////////////////////////
